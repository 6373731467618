import React from "react";

function Arrow() {
	return (
		<svg viewBox="0, 0, 400,398.74213836477986" class="w-3 fill-current inline mb-1 ml-1">
			<path
				d="M91.054 1.660 C 63.419 12.142,61.419 51.851,87.862 65.011 C 92.140 67.140,98.906
	67.338,187.120 67.925 L 281.788 68.553 143.420 206.883 C 65.662 284.620,4.084 347.157,2.841
	349.650 C -11.178 377.774,20.974 409.391,49.686 395.717 C 53.099 394.091,99.307 348.800,193.373
	254.879 L 332.028 116.437 332.366 210.734 C 332.683 298.935,332.852 305.328,334.989 309.622 C
	347.310 334.379,384.765 334.379,397.086 309.622 C 400.635 302.493,400.635 25.809,397.086 18.680
	C 394.164 12.807,387.193 5.836,381.320 2.914 C 375.561 0.047,98.461 -1.150,91.054 1.660 "
			/>
		</svg>
	);
}

export default Arrow;
